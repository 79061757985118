.phone-input {
  width: 98%;
}

.PhoneInputInput {
  font-family: Ubuntu, sans-serif;
  height: 2.25rem;
  -webkit-tap-highlight-color: transparent;
  line-height: calc(2.125rem);
  appearance: none;
  resize: none;
  box-sizing: border-box;
  font-size: 0.875rem;
  width: 100%;
  color: rgb(0, 0, 0);
  display: block;
  text-align: left;
  border: 0.0625rem solid rgb(206, 212, 218);
  background-color: rgb(255, 255, 255);
  transition: border-color 100ms ease 0s;
  min-height: 2.25rem;
  padding-left: calc(0.75rem);
  padding-right: calc(0.75rem);
  border-radius: 2rem;
}

.error {
  .PhoneInputInput {
    color: #ca7d71;
    border-color: #ca7d71;

    &::placeholder {
      color: #ca7d71;
    }
  }
}

.error-message {
  font-family: Ubuntu, sans-serif;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  word-break: break-word;
  color: rgb(202, 125, 113);
  font-size: calc(0.75rem);
  line-height: 1.2;
  display: block;
  width: 100%;
}
